<template>
    <header class="page-title">
        <h1><i class="bi bi-list-ul"></i> 部門マスタ</h1>
    </header>

    <section class="section">
        <div class="col-sm-36 col-md-18 col-lg-12">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-4"></th>
                        <th class="text-center col-24">部門名</th>
                        <th class="text-center col-8"></th>
                    </tr>
                </thead>
                <template v-if="loading">
                    <tbody>
                        <tr v-for="i in Array(3)" :key="i">
                            <td class="align-middle text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                            <td class="align-middle"><input type="text" class="form-control" disabled></td>
                            <td class="text-nowrap text-center">
                                <button type="submit" class="btn btn-info me-2" disabled>保存</button>
                                <button type="button" class="btn btn-outline-danger ms-2" disabled>削除</button>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-else-if="departments.length">
                    <draggable
                            v-model="departments"
                            item-key="department_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="align-middle text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle"><form-input required v-model="element.department_name" /></td>
                                <td class="text-nowrap text-center">
                                    <button type="submit" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input required v-model="department_to_create.department_name" :disabled="loading" /></td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create" :disabled="loading">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import Department from '@/models/entities/department';
import FormInput from '@/components/forms/FormInput';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'MasterDepartment',
    components: {
        draggable,
        FormInput,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            //ローディング
            loading: false,

            //表示データ
            departments: [],

            //新規登録事業部
            department_to_create: new Department(),
            //削除対象事業部
            department_to_remove: null,
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        //検索
        search() {
            //表示データ初期化
            this.departments.splice(0);
            //ローディング開始
            this.loading = true;
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    this.departments.push(new Department(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading = false;
            });
        },
        //新規登録
        create() {
            if (this.$helper.isBlank(this.department_to_create.department_name)) {
                this.showErrorMessage('事業部名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.post('/master/department', this.department_to_create)
            .then(response => {
                // 登録されたデータをリストに追加
                this.departments.push(new Department(response.data));
                // 追加行を初期化
                this.department_to_create = new Department();

                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //保存
        update(department) {
            if (this.$helper.isBlank(department.department_name)) {
                this.showErrorMessage('事業部名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/department/${department.department_id}`, department)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //削除確認
        removeReady(department) {
            this.department_to_remove = department;
            this.$refs.confirmRemove.show();
        },
        //削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/department/${this.department_to_remove.department_id}`)
            .then(() => {
                // リストから削除
                let removed_index = this.departments.findIndex((row) => row.department_id === this.department_to_remove.department_id);
                this.departments.splice(removed_index, 1);
                // 値をリセット
                this.department_to_remove = null;

                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //ソート
        sort() {
            const department_ids = this.departments.map(department => department.department_id);

            this.$http.post('/master/department/sort', {
                department_ids: department_ids,
            });
        },
    }
}
</script>

<style scoped>

</style>
